import {Head} from '@inertiajs/react';
import {PageProps} from '@/types';
import MissionStatement from "@/Pages/Welcome/partials/MissionStatement";
import Section from "@/Components/Ui/Layout/Section";
import MemberLayout from "@/Layouts/MemberLayout";
import React from "react";
import CardCarousel from "@/Components/Recipe/CardCarousel";
import CardGrid from "@/Components/Recipe/CardGrid";
import RecipeData = App.Data.RecipeData;

interface WelcomePageProps extends PageProps {
  latestRecipes: RecipeData[]
  featuredRecipes: RecipeData[]
  recipeCount: number
  ingredientCount: number
  tagCount: number
  tipCount: number
}

export default function WelcomePage(props: WelcomePageProps) {

  const {
    auth,
    latestRecipes,
    featuredRecipes,
  } = props
  return (
    <>
      <Head title="Welcome"/>
      <MemberLayout user={auth.user}>
        
        <section className='max-w-7xl mx-auto text-black text-pretty min-h-11'>
          <CardCarousel recipes={featuredRecipes}/>
        </section>

        <Section
          title='New Recipes Every Week!'
          tagLine='Each week you can come here to find new recipes that are easy to make and delicious to eat.'
          className='py-12 pb-6'
        >
          <CardGrid recipes={latestRecipes} color='black'/>
        </Section>

        <MissionStatement
          ingredientCount={props.ingredientCount}
          recipeCount={props.recipeCount}
          tagCount={props.tagCount}
          tipCount={props.tipCount}
        />

      </MemberLayout>
    </>
  );
}
